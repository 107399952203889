// src/PrivacyPolicy.js

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ marginLeft: '15%', marginRight: '15%', padding: '20px', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p>Effective Date: [09/07/2024]</p>

      <p>
        At Republicans.co, we prioritize your privacy and are committed to transparency about how we collect, use, and protect your information. 
        This privacy policy explains how personal data is collected and used when you visit our website. By using this site, you consent to the terms 
        outlined in this policy.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect personal information from you in a variety of ways, including:
        <ul>
          <li><strong>Automatically Collected Information:</strong> We automatically collect certain information when you visit our website. This may include your IP address, browser type, access times, and the referring website. This information helps us improve your user experience and manage our site.</li>
          <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience on our site. Cookies allow us to recognize and maintain your preferences during your visits. You can manage or disable cookies through your browser settings. By using this site, you agree to the use of cookies.</li>
        </ul>
      </p>

      <h2>2. Google AdSense and Third-Party Advertising</h2>
      <p>
        This website uses Google AdSense to display ads. Google AdSense may use cookies to display personalized ads based on your browsing history. Third-party vendors, including Google, use cookies to serve ads based on your visit to this website or other websites.
      </p>
      <p>
        <strong>Google's Use of the DoubleClick Cookie:</strong> Google and its partners use cookies, including the DoubleClick cookie, to serve ads based on your visit to this site and/or other sites on the internet.
      </p>
      <p>
        <strong>Opting Out of Personalized Ads:</strong> You may opt out of personalized advertising by visiting the <a href="https://adssettings.google.com" target="_blank" rel="noopener noreferrer">Google Ads Settings</a> page. Alternatively, you can opt out of a third-party vendor’s use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info/choices" target="_blank" rel="noopener noreferrer">www.aboutads.info/choices</a>.
      </p>

      <h2>3. Use of Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Improve our website’s functionality and user experience.</li>
        <li>Personalize the content and ads displayed to you.</li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h2>4. Google Publisher Policies</h2>
      <p>
        As a participant in the Google AdSense program, Republicans.co adheres to all Google Publisher Policies:
      </p>
      <ul>
        <li><strong>Illegal Content:</strong> We do not publish any content that is illegal or promotes illegal activity.</li>
        <li><strong>Dangerous or Derogatory Content:</strong> We do not allow content that incites hatred, promotes discrimination, or disparages individuals or groups based on their race, religion, gender, or other characteristics.</li>
        <li><strong>Intellectual Property:</strong> We respect intellectual property laws and respond to valid requests under the Digital Millennium Copyright Act (DMCA).</li>
        <li><strong>Misrepresentation:</strong> We do not misrepresent our content, identity, or intentions in any way.</li>
      </ul>
      <p>For a detailed list of Google Publisher Policies, visit the <a href="https://support.google.com/adsense/answer/1348688?hl=en" target="_blank" rel="noopener noreferrer">Google Publisher Policies</a> page.</p>

      <h2>5. Data Sharing</h2>
      <p>
        We may share non-personally identifiable information with third-party partners for advertising, analytics, or other business purposes. These partners may use cookies, web beacons, and other tracking technologies to collect information about your use of this site. 
        You can learn more about opting out of third-party cookies and data collection by visiting <a href="http://www.aboutads.info" target="_blank" rel="noopener noreferrer">www.aboutads.info</a>.
      </p>

      <h2>6. Security</h2>
      <p>
        We take reasonable precautions to protect your personal information. However, no system is entirely secure, and we cannot guarantee the complete security of your information.
      </p>

      <h2>7. Children's Privacy</h2>
      <p>
        This site is not directed to children under the age of 13, and we do not knowingly collect information from children. If we discover that a child under the age of 13 has provided us with personal information, we will promptly delete it in compliance with the Children’s Online Privacy Protection Act (COPPA).
      </p>

      <h2>8. User Rights and Consent</h2>
      <p>
        By using this website, you acknowledge and consent to the following:
      </p>
      <ul>
        <li>The collection and use of your personal data as described in this privacy policy.</li>
        <li>The placement of cookies on your device for personalized advertising.</li>
        <li>The potential sharing of non-personally identifiable information with third parties for analytics and advertising purposes.</li>
      </ul>
      <p>
        You can withdraw consent at any time by managing your cookie settings or opting out of personalized ads.
      </p>

      <h2>9. Updates to This Privacy Policy</h2>
      <p>
        We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically. Any changes will be effective immediately upon posting.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about this privacy policy, please contact us at:
      </p>
      <p>Email: support@republicans.co</p>
    </div>
  );
};

export default PrivacyPolicy;
