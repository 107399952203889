import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import NewsList from './NewsList';
import PrivacyPolicy from './privacypolicy';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header" style={{ backgroundColor: 'darkblue', textAlign: 'middle', padding: '10px', fontSize: '12px'}}>
          <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
            <h1 style={{ color: 'white' }}>Republicans.co</h1>
          </Link>
        </header>

        {/* Leaderboard Ad - Top */}
        <div className="ad-banner" style={{ textAlign: 'center', margin: '10px 0' }}>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1657419980516513"
            crossorigin="anonymous"></script>
          <ins className="adsbygoogle"
            style={{ display: 'inline-block', width: '728px', height: '90px' }}
            data-ad-client="ca-pub-1657419980516513"
            data-ad-slot="7759448475"></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>

        <main style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Main Content */}
          <div style={{ flex: '1', marginRight: '20px' }}>
            <Routes>
              <Route path="/" element={<NewsList />} /> {/* Home/NewsList */}
              <Route path="/privacypolicy" element={<PrivacyPolicy />} /> {/* Privacy Policy Page */}
            </Routes>

            {/* Ad - Inside the content (fluid ad format or inline ad) */}
            <div className="ad-banner" style={{ margin: '20px 0' }}>
              <ins className="adsbygoogle"
                  style={{ display: 'block' }}
                  data-ad-client="ca-pub-1657419980516513"
                  data-ad-slot="your-ad-slot"
                  data-ad-format="auto"></ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            </div>
          </div>

          {/* Sidebar Ad */}
          <aside style={{ width: '300px' }}>
            <div className="ad-banner" style={{ textAlign: 'center' }}>
              <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1657419980516513"
                      crossorigin="anonymous"></script>
              <ins className="adsbygoogle"
                  style={{ display: 'inline-block', width: '300px', height: '250px' }}
                  data-ad-client="ca-pub-1657419980516513"
                  data-ad-slot="1609534174"></ins>
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
            </div>
          </aside>
        </main>

        {/* Leaderboard Ad - Bottom */}
        <div className="ad-banner" style={{ textAlign: 'center', margin: '20px 0' }}>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1657419980516513"
            crossorigin="anonymous"></script>
          <ins className="adsbygoogle"
            style={{ display: 'inline-block', width: '728px', height: '90px' }}
            data-ad-client="ca-pub-1657419980516513"
            data-ad-slot="7759448475"></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>

        {/* Anchor Ad (fixed to the bottom of the page) */}
        <div className="anchor-ad" style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 100 }}>
          <ins className="adsbygoogle"
               style={{ display: 'block' }}
               data-ad-client="ca-pub-1657419980516513"
               data-ad-slot="your-anchor-ad-slot"
               data-ad-format="auto"></ins>
          <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
          </script>
        </div>

        <footer>
          <p>
            © {new Date().getFullYear()} Republicans.co® is not affiliated with, authorized, or sponsored by the Republican Party or any candidates. The content provided on this site is for informational purposes only and does not constitute endorsements of any kind. All Rights Reserved.
          </p>
          <p style={{ marginTop: '10px' }}>
            <Link to="/privacypolicy" style={{ color: 'white', textDecoration: 'underline' }}>
              Privacy Policy
            </Link>
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
