// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Optional: Add your custom CSS here
import App from './App';

// Add Google AdSense script
const addGoogleAdSense = () => {
  const script = document.createElement('script');
  script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  script.async = true;
  script.crossOrigin = 'anonymous';
  script.setAttribute('data-ad-client', 'ca-pub-1657419980516513');
  document.body.appendChild(script);
};



addGoogleAdSense();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
