import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsList.css'; // Ensure this path is correct

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get('https://api.gdeltproject.org/api/v2/doc/doc', {
          params: {
            query: 'trump', // You can adjust this query as needed
            mode: 'artlist', // To get a list of articles
            format: 'json', // JSON format
            sourceLang: 'en', 
            maxrecords: '250',
            timespan: '1d',
            image: 'yes',
            sort: 'date'
          },
        });

        // Filter out duplicate headlines by title
        let filteredNews = response.data.articles.filter(article =>
          (article.language === 'English' || article.language === 'en') && 
          (article.image_url || article.socialimage)
        );

        // Ensure unique articles
        filteredNews = filteredNews.reduce((unique, current) => {
          if (!unique.some(article => article.title.toLowerCase() === current.title.toLowerCase())) {
            unique.push(current);
          }
          return unique;
        }, []);

        if (filteredNews.length) {
          setNews(filteredNews);
        } else {
          setError('No news available.');
        }
      } catch (err) {
        if (err.response) {
          setError(`Error fetching news: ${err.response.status} - ${err.response.statusText}`);
        } else {
          setError('Error fetching news. Please check your network or query.');
        }
      }
    };

    fetchNews();
  }, []); // Empty dependency array to run once on component mount

  return (
    <div className="news-container">
      <h2>Top Republican News</h2>
      <p>Welcome to Republicans.co, where we bring you the latest and most relevant news in conservative politics. Below is a curated list of top stories that are making headlines. Stay informed with the most up-to-date information and insights into key Republican events.</p>

      {error ? (
        <p>{error}</p>
      ) : (
        <ul className="news-list">
          {news.map((article, index) => (
            <React.Fragment key={index}>
              <li className="news-item">
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                  <h3>{article.title}</h3>
                  <p className="news-description">
                    Published by {article.domain}
                  </p>
                  {(article.image_url || article.socialimage) && (
                    <img
                      src={article.image_url || article.socialimage}
                      alt={article.title}
                      className="news-image"
                    />
                  )}
                </a>
              </li>

              {/* Insert ad after every 3rd article */}
              {index % 3 === 2 && (
                <li className="ad-item">
                  <div className="ad-banner">
                    <ins className="adsbygoogle"
                      style={{ display: 'block' }}
                      data-ad-client="ca-pub-1657419980516513"
                      data-ad-slot="2604218938"
                      data-ad-format="fluid"
                      data-ad-layout-key="-6t+ed+2i-1n-4w"></ins>
                    <script>
                      (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NewsList;
